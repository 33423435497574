var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"eventmodal"},[_c('b-modal',{attrs:{"width":640,"has-modal-card":"","scroll":"keep"},on:{"close":_vm.onClose},model:{value:(_vm.ismodal),callback:function ($$v) {_vm.ismodal=$$v},expression:"ismodal"}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-image"},[(_vm.modaldata.properties.WebsiteImage.files.length != 0)?_c('b-image',{attrs:{"src":_vm.modaldata.properties.WebsiteImage.files[0].file.url,"placeholder":require("@/assets/ImagePlaceholder.png"),"ratio":"4by4"}}):_vm._e()],1),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},[(_vm.modaldata.properties.WebsiteHeadline.rich_text.length != 0)?_c('h2',{staticClass:"is-size-3 is-size-4-mobile mb-0 has-text-primary"},[_vm._v(" "+_vm._s(_vm.modaldata.properties.WebsiteHeadline.rich_text[0].plain_text)+" ")]):_vm._e(),_c('p',[(
                _vm.modaldata.properties.Datum.date.end == null &&
                _vm.modaldata.properties.Datum.date.start.length == 10
              )?_c('span',{staticClass:"is-size-6"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.modaldata.properties.Datum.date.start,"DD.MM.YYYY"))+" ")]):(
                _vm.modaldata.properties.Datum.date.end == null &&
                _vm.modaldata.properties.Datum.date.start.length != 10
              )?_c('span',{staticClass:"is-size-6"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.modaldata.properties.Datum.date.start,"DD.MM.YYYY"))+" ab "+_vm._s(_vm._f("moment")(_vm.modaldata.properties.Datum.date.start,"HH:mm"))+" ")]):(
                _vm.modaldata.properties.Datum.date.end != null &&
                _vm.modaldata.properties.Datum.date.start.length != 10 &&
                _vm.modaldata.properties.Datum.date.start.slice(0, -19) ==
                  _vm.modaldata.properties.Datum.date.end.slice(0, -19)
              )?_c('span',{staticClass:"is-size-6"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.modaldata.properties.Datum.date.start,"DD.MM.YYYY"))+" von "+_vm._s(_vm._f("moment")(_vm.modaldata.properties.Datum.date.start,"HH:mm"))+" bis "+_vm._s(_vm._f("moment")(_vm.modaldata.properties.Datum.date.end,"HH:mm"))+" ")]):_vm._e(),(
                _vm.modaldata.properties.Datum.date.end != null &&
                _vm.modaldata.properties.Datum.date.end.length == 10
              )?_c('span',{staticClass:"is-size-6"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.modaldata.properties.Datum.date.start,"DD.MM.YYYY"))+" bis "+_vm._s(_vm._f("moment")(_vm.modaldata.properties.Datum.date.end,"DD.MM.YYYY"))+" ")]):_vm._e(),(
                _vm.modaldata.properties.Datum.date.end != null &&
                _vm.modaldata.properties.Datum.date.end.length != 10 &&
                _vm.modaldata.properties.Datum.date.start.slice(0, -19) !=
                  _vm.modaldata.properties.Datum.date.end.slice(0, -19)
              )?_c('span',{staticClass:"is-size-6"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.modaldata.properties.Datum.date.start,"DD.MM.YYYY"))+", "+_vm._s(_vm._f("moment")(_vm.modaldata.properties.Datum.date.start,"HH:mm"))+" bis "+_vm._s(_vm._f("moment")(_vm.modaldata.properties.Datum.date.end,"DD.MM.YYYY"))+", "+_vm._s(_vm._f("moment")(_vm.modaldata.properties.Datum.date.end,"HH:mm"))+" ")]):_vm._e(),(_vm.modaldata.properties.WebsiteOrt.rich_text.length != 0)?_c('span',{staticClass:"is-size-6"},[_vm._v(" | "+_vm._s(_vm.modaldata.properties.WebsiteOrt.rich_text[0].plain_text)+" ")]):_vm._e()]),(_vm.modaldata.properties.WebsiteText.rich_text.length != 0)?_c('p',{staticClass:"is-size-6 eventtext"},[_vm._v(" "+_vm._s(_vm.modaldata.properties.WebsiteText.rich_text[0].plain_text)+" ")]):_vm._e(),(
              _vm.modaldata.properties.WebsiteLink.url ||
              _vm.modaldata.properties.WebsiteFile.files.length != 0 ||
              _vm.modaldata.properties.WebsiteVideoLink.url
            )?_c('p',{staticClass:"is-size-6"},[(_vm.modaldata.properties.WebsiteFile.files.length != 0)?_c('a',{staticClass:"is-size-6 button is-medium is-primary is-outlined mr-5 mb-3",attrs:{"href":_vm.modaldata.properties.WebsiteFile.files[0].file.url,"target":"_blank"}},[_c('b-icon',{attrs:{"icon":"file-download-outline","size":"is-small"}}),_vm._v("    Infos downloaden")],1):_vm._e(),(_vm.modaldata.properties.WebsiteLink.url)?_c('a',{staticClass:"is-size-6 button is-medium is-primary is-outlined mr-5 mb-3",attrs:{"href":_vm.modaldata.properties.WebsiteLink.url,"target":"_self"}},[_c('b-icon',{attrs:{"icon":"link-variant","size":"is-small"}}),_vm._v("    mehr Informationen")],1):_vm._e(),(_vm.modaldata.properties.WebsiteVideoLink.url)?_c('a',{staticClass:"is-size-6 button is-medium is-primary is-outlined mr-5 v",attrs:{"href":_vm.modaldata.properties.WebsiteVideoLink.url,"target":"_blank"}},[_c('b-icon',{attrs:{"icon":"video-outline","size":"is-small"}}),_vm._v("    online einwählen")],1):_vm._e()]):_vm._e()])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <b-navbar
    type="is-primary"
    wrapper-class="container is-fluid"
    fixed-top
    transparent
    :class="{ smallnav: scrollPosition > 35 }"
  >
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <LogoTeStUp />
      </b-navbar-item>
      <div class="hexagon"></div>
    </template>

    <template slot="start"></template>

    <template slot="end">
      <b-navbar-dropdown label="Angebot" :collapsible="true">
        <b-navbar-item tag="router-link" :to="{ path: '/kickstart' }">
          Kickstart
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/mentoring' }">
          Mentoring
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/opportunityspace' }">
          Opportunity Space
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/ideenreich' }">
          FabLab IDEENREICH
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/designlab' }">
          DesignLab
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/softwarefactory' }">
          Software Factory
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/e3lab' }">
          E3 Lab
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/techshop' }">
          TechShop
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/usabilitylab' }">
          Usability Lab
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/studieren' }">
          studieren
        </b-navbar-item>
      </b-navbar-dropdown>
      <b-navbar-dropdown label="Mitmachen" :collapsible="true">
        <b-navbar-item tag="router-link" :to="{ path: '/wer-kann-mitmachen' }">
          Wer kann mitmachen?
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/wie-mitmachen' }">
          Wie kann ich mitmachen?
        </b-navbar-item>
        <hr aria-role="menuitem" class="dropdown-divider" />
        <b-navbar-item tag="router-link" :to="{ path: '/mentoring-beratung' }">
          Mentoring & Beratung
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/tools-technik' }">
          Tools & Technik
        </b-navbar-item>
        <!--<b-navbar-item tag="router-link" :to="{ path: '/methoden' }">
          Methoden
        </b-navbar-item>-->
        <b-navbar-item
          tag="router-link"
          :to="{ path: '/finanzielle-foerderung' }"
        >
          Finanzielle Förderung
        </b-navbar-item>
      </b-navbar-dropdown>
      <b-navbar-dropdown label="Veranstaltungen" :collapsible="true">
        <b-navbar-item tag="router-link" :to="{ path: '/uebersicht' }">
          Übersicht
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/openlabs' }">
          Open Labs
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/bbq' }">
          BBQ
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/testupbbqonstage' }">
          TeStUp BBQ ON STAGE
        </b-navbar-item>
        <!--<b-navbar-item tag="router-link" :to="{ path: '/TechWednesday' }">
          TechWednesday
        </b-navbar-item>-->
        <b-navbar-item tag="router-link" :to="{ path: '/help2kickstart' }">
          help2kickstart
        </b-navbar-item>
        <!--<b-navbar-item tag="router-link" :to="{ path: '/itstimetotestup' }">
          It's Time to TeStUp
        </b-navbar-item>-->
        <!--<b-navbar-item tag="router-link" :to="{ path: '/www' }">
          Was wäre wenn…
        </b-navbar-item>-->
        <b-navbar-item tag="router-link" :to="{ path: '/studierenprobieren' }">
          Studieren probieren
        </b-navbar-item>
      </b-navbar-dropdown>
      <b-navbar-dropdown label="Weg durch TeStUp" :collapsible="true">
        <b-navbar-item tag="router-link" :to="{ path: '/orientierung' }">
          Orientierung
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/ideenzyklus' }">
          Ideenzyklus
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/konzeptzyklus' }">
          Konzeptzyklus
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/entwicklungszyklus' }">
          Entwicklungszyklus
        </b-navbar-item>
        <b-navbar-item
          tag="router-link"
          :to="{ path: '/markteinfuehrungszyklus' }"
        >
          Markteinführungszyklus
        </b-navbar-item>
      </b-navbar-dropdown>
      <b-navbar-dropdown label="Über uns" :collapsible="true">
        <b-navbar-item tag="router-link" :to="{ path: '/kontakt' }">
          Kontakt
        </b-navbar-item>
        <!--<b-navbar-item tag="router-link" :to="{ path: '/beispiele' }">
          Gründungsprojekte und Beispiele
        </b-navbar-item>-->
        <b-navbar-item tag="router-link" :to="{ path: '/erfolge' }">
          Erfolge
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/startuplab' }">
          Förderprogramm <br />StartUpLab@FH
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/team' }">
          Köpfe im Projekt
        </b-navbar-item>
        <!--<b-navbar-item tag="router-link" :to="{ path: '/partner' }">
          Parter*innen
        </b-navbar-item>-->
        <b-navbar-item
          tag="router-link"
          :to="{ path: '/entrepreneurship-hs-flensburg' }"
        >
          Gründungslandschaft <br />HS Flensburg
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/lehre' }">
          TeStUp in der Lehre
        </b-navbar-item>
      </b-navbar-dropdown>
      <div class="navspacer"></div>
      <b-navbar-item
        tag="a"
        href="https://hs-flensburg.de"
        target="_blank"
        class="fuaslogo"
      >
        <LogoHSFL1C /><LogoHSFL />
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import LogoTeStUp from "@/components/layout/LogoTeStUp.vue";
import LogoHSFL1C from "@/components/layout/LogoHSFL1C.vue";
import LogoHSFL from "@/components/layout/FooterLogos/LogoHSFL.vue";
export default {
  name: "Navbar",
  components: {
    LogoTeStUp,
    LogoHSFL1C,
    LogoHSFL,
  },
  data() {
    return {
      scrollPosition: null,
    };
  },
  methods: {
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
};
</script>

<style lang="scss">
html.has-navbar-fixed-top,
body.has-navbar-fixed-top {
  padding-top: 12.5rem !important;
}

.hsfllogo {
  display: none;
}

.navbar.is-primary {
  background: rgba(109, 63, 146, 0.8) !important;
  -webkit-backdrop-filter: blur(16px);
  backdrop-filter: blur(16px);
}

nav .hexagon {
  position: absolute;
  width: 50rem;
  height: 50rem;
  left: -19rem;
  top: -35rem;
  transition: all 0.5s ease;
  background: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMTEwIDk3IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbDpzcGFjZT0icHJlc2VydmUiIHN0eWxlPSJmaWxsLXJ1bGU6ZXZlbm9kZDtjbGlwLXJ1bGU6ZXZlbm9kZDtzdHJva2UtbGluZWpvaW46cm91bmQ7c3Ryb2tlLW1pdGVybGltaXQ6MiI+PHBhdGggZD0iTTE1NTguMTIgOTczLjU1NmE0LjM0MSA0LjM0MSAwIDAgMSAwLTQuMzUzYzQuNjEtNy45OTUgMjAuNi0zNS42OTMgMjUuMjItNDMuNjgzYTQuMzQ3IDQuMzQ3IDAgMCAxIDMuNzctMi4xOGg1MC40NWMxLjU1IDAgMi45OS44MyAzLjc3IDIuMTggNC42MSA3Ljk5IDIwLjYgMzUuNjg4IDI1LjIyIDQzLjY4M2E0LjM0MSA0LjM0MSAwIDAgMSAwIDQuMzUzYy00LjYyIDcuOTk2LTIwLjYxIDM1LjY5NC0yNS4yMiA0My42ODRhNC4zNiA0LjM2IDAgMCAxLTMuNzcgMi4xOGgtNTAuNDVjLTEuNTYgMC0yLjk5LS44My0zLjc3LTIuMTgtNC42Mi03Ljk5LTIwLjYxLTM1LjY4OC0yNS4yMi00My42ODRaIiBzdHlsZT0iZmlsbDojZmZmIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTU1Ny41NCAtOTIzLjM0KSIvPjwvc3ZnPg==")
    no-repeat;
}

a.navbar-item,
a.navbar-link {
  font-weight: 700;
  font-size: 1.3rem;
  padding: 0.75rem 0.75rem;
  margin: 0rem 0.75rem;
  height: 6rem;
  transition: all 1s ease;
  svg.Mainlogo {
    height: 6.5rem;
    margin-top: 2.5rem;
    z-index: 1;
    transition: all 1s ease;
    & path {
      transition: all 1s ease;
      &.logotext {
        transition: all 1s ease;
      }
    }
  }
  svg.hsfllogo1c {
    width: 6.5rem;
  }
}

.navspacer {
  width: 2rem;
}

a.navbar-link:not(.is-arrowless) {
  padding-right: 2em;
}
a.navbar-link:not(.is-arrowless)::after {
  margin-top: -0.5em;
  right: 0.75em;
  font-size: 0.9rem;
}
.navbar.is-primary .navbar-end > a.navbar-item.is-active,
.navbar.is-primary .navbar-end > a.navbar-item:hover,
.navbar.is-primary .navbar-end > .navbar-item a.navbar-link:hover {
  border-bottom: 4px solid $white-ter;
  color: $white-ter !important;
}

.navbar-brand > a.navbar-item,
.navbar-brand > a.navbar-item.is-active,
.navbar-burger:hover,
.navbar-item.has-dropdown:hover .navbar-link {
  background-color: transparent !important;
}

.navbtn {
  margin-left: 1rem;
  font-size: 1.2rem;
}

.navbar.is-transparent {
  .navbar-dropdown {
    margin-top: -4px;
    border-top: 4px solid $info !important;
    a.navbar-item {
      height: 4rem;
      color: #3c3c3b;
      &:hover {
        color: $info !important;
      }
      &.is-active {
        color: $primary !important;
      }
    }
  }
}

.navbar-dropdown {
  a {
    .navbar-item {
      padding: 50rem !important;
      .is-active {
        color: $twitter !important;
      }
    }
  }
}

@media screen and (max-width: $tablet - 1px) {
  .smallnav .hexagon {
    top: -45rem;
  }
  .smallnav .navbar-menu.is-active {
    margin-top: 1rem;
    transition: all 1s ease;
  }
  .smallnav a.navbar-item,
  .smallnav a.navbar-link {
    svg.Mainlogo {
      height: 3rem;
      margin-top: 0rem;
      & path {
        fill: $background !important;
        &.logotext {
          fill: transparent !important;
        }
      }
    }
  }
}

@media screen and (max-width: $desktop - 1px) {
  html.has-navbar-fixed-top,
  body.has-navbar-fixed-top {
    padding-top: 10rem !important;
  }
  .hsfllogo1c,
  .navspacer {
    display: none;
  }
  .hsfllogo {
    height: 5rem;
    display: block;
  }
  nav .hexagon {
    left: -22.5rem;
    top: -37rem;
  }
  .navbar-menu.is-active {
    margin-top: 5rem;
    transition: all 1s ease;
    border-radius: 5px;
    a.navbar-item {
      height: auto;
      &.fuaslogo {
        display: flex;
        justify-content: center;
      }
    }
  }
  .navbar.is-primary .navbar-end > a.navbar-item {
    &.is-active {
      color: $primary !important;
      border-bottom: 0px solid $white;
      background-color: $background;
    }
    &:hover {
      color: $info !important;
      border-bottom: 0px solid $white;
      background-color: $background;
    }
  }
  .navbar.is-primary .navbar-end > .navbar-item a.navbar-link:hover {
    color: $info !important;
    border-bottom: 0px solid $white;
    background-color: $background;
  }
  .navbar.is-transparent .navbar-dropdown {
    margin-top: 0px;
    border-top: 0px solid $info !important;
  }
  a.navbar-item:focus,
  a.navbar-item:focus-within,
  a.navbar-item:hover,
  a.navbar-item.is-active,
  .navbar-link:focus,
  .navbar-link:focus-within,
  .navbar-link:hover,
  .navbar-link.is-active {
    background-color: $background !important;
  }
  .navbar.is-transparent .navbar-dropdown a.navbar-item {
    padding-left: 2.5rem;
  }
  a.navbar-item,
  a.navbar-link {
    height: 4rem;
    svg.Mainlogo {
      height: 5rem;
      margin-top: 2.5rem;
      margin-left: -1.5rem;
    }
    svg.hsfllogo1c {
      width: 6.5rem;
    }
  }
  .navbar-burger {
    margin-top: 0.25rem !important;
  }
  .navbtn {
    margin-left: 0.75rem;
    margin-bottom: 1.5rem !important;
  }
}

//desktop-only, 1216px
@media screen and (min-width: $desktop) and (max-width: $widescreen - 1px) {
  a.navbar-item,
  a.navbar-link {
    font-size: 1rem;
    padding: 0.35rem 0.35rem;
    margin: 0rem 0.35rem;
  }
  .navspacer {
    width: 0rem;
  }
  .navbar-item.fuaslogo {
    display: none;
  }
}
//widescreen-only, 1407x
@media screen and (min-width: $widescreen) and (max-width: $fullhd - 1px) {
  a.navbar-item,
  a.navbar-link {
    font-size: 1.15rem;
    padding: 0.3rem 0.3rem;
    margin: 0rem 0.3rem;
  }
  .navspacer {
    width: 0.5rem;
  }
}
</style>

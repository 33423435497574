<template>
  <div class="level-left legalnav is-mobile">
    <div class="level-item">
      <a
        href="/kontakt"
        class="has-text-weight-bold is-size-5 is-size-6-desktop is-size-6-mobile"
        >Kontakt</a
      >
    </div>
    <div class="level-item">
      <a
        href="/lageplan"
        class="has-text-weight-bold is-size-5 is-size-6-desktop is-size-6-mobile"
        >Lageplan</a
      >
    </div>
    <div class="level-item">
      <a
        href="/datenschutz"
        class="has-text-weight-bold is-size-5 is-size-6-desktop is-size-6-mobile"
        >Datenschutz</a
      >
    </div>
    <div class="level-item">
      <a
        href="/impressum"
        class="has-text-weight-bold is-size-5 is-size-6-desktop is-size-6-mobile"
        >Impressum</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Legalnav",
  components: {},
  data() {
    return {};
  },
};
</script>

<style lang="scss"></style>

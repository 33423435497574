<template>
  <svg
    viewBox="0 0 320 126"
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    class="Mainlogo"
    style="
      fill-rule: evenodd;
      clip-rule: evenodd;
      stroke-linejoin: round;
      stroke-miterlimit: 2;
    "
  >
    <path
      d="M1720.63 1006.03v-6.226h-3.28v-21.623h4.3l.14 3.668h5.94v-9.899h-28.58v9.899h5.84l.15-3.668h4.25v21.623h-3.29v6.226h14.53Z"
      style="fill: #3c3c3b; fill-rule: nonzero"
      class="logotext"
      transform="translate(-1540.6 -943.84)"
    />
    <path
      d="M1734.1 986.334h14.48c.09-.869.14-2.269.14-2.848 0-7.674-.91-12.067-10.62-12.067-8.35 0-11.82 3.475-11.82 12.839 0 8.592 2.7 12.549 11.78 12.549 4 0 8.35-.675 10.71-1.979l-1.59-5.357c-1.35.531-4.06 1.303-7.82 1.303-3.28 0-4.97-.82-5.26-4.44Zm.09-5.213c.1-3.041 1.16-4.441 3.87-4.441 2.94 0 3.57 1.159 3.57 3.717 0 .193 0 .531-.05.724h-7.39Z"
      style="fill: #3c3c3b; fill-rule: nonzero"
      class="logotext"
      transform="translate(-1540.6 -934.086)"
    />
    <path
      d="M1751.76 995.218c0 2.22.2 4.585.92 6.422 1.35 3.38 5.26 4.92 12.12 4.92 11.29 0 14.52-3.72 14.52-10.425 0-6.227-1.69-8.978-8.25-10.136l-6.81-1.158c-2.94-.531-3.47-1.979-3.47-3.524 0-2.172 1.35-3.528 5.31-3.528 2.8 0 3.81.584 4.34 1.26.53.627.73 1.979.77 2.944h7.39c0-1.738-.1-4.154-.77-5.984-1.26-3.48-5.26-4.59-11.97-4.59-9.9 0-13.13 4.3-13.13 10.526 0 6.274 2.6 9.17 8.25 10.087l6.32 1.062c3.24.579 3.82 1.4 3.82 3.572 0 2.413-1.5 3.524-5.84 3.524-3.09 0-4.64-.821-5.22-1.787-.53-.772-.77-2.027-.77-3.185h-7.53Z"
      style="fill: #3c3c3b; fill-rule: nonzero"
      class="logotext"
      transform="translate(-1540.6 -943.839)"
    />
    <path
      d="m1798 1002.06-.48-6.077c-.82.241-1.98.289-2.99.289-1.11 0-1.79-.241-2.03-.772-.34-.676-.39-1.786-.39-3.041v-8.205h5.12v-6.226h-5.12v-6.513h-7.28l-.2 6.513h-3.47v6.226h3.28v10.232c0 2.896.43 4.779 1.4 6.034 1.01 1.3 2.9 2.22 6.32 2.22 2.75 0 4.49-.29 5.84-.68Z"
      style="fill: #3c3c3b; fill-rule: nonzero"
      class="logotext"
      transform="translate(-1540.6 -940.115)"
    />
    <path
      d="M1832.42 977.65v-6.231h-12.99v6.231h2.75v15.831c0 2.365-.14 3.62-1.01 4.682-.97 1.207-3.14 1.448-5.07 1.448-2.12 0-3.86-.338-4.78-1.545-.82-1.11-.96-2.509-.96-4.633V977.65h2.75v-6.231h-13.37v6.231h2.7v14.238c0 3.765.15 7.095 1.11 9.222 1.84 4 6.86 4.92 12.12 4.92 6.37 0 10.71-1.64 12.55-4.63 1.2-1.982 1.49-4.733 1.49-9.608V977.65h2.71Z"
      style="fill: #3c3c3b; fill-rule: nonzero"
      class="logotext"
      transform="translate(-1540.6 -943.309)"
    />
    <path
      d="M1847.76 996.807v-6.178h-3.57v-6.757c1.06 1.062 3.09 1.641 5.31 1.641 7.63 0 10.91-2.075 10.91-13.177 0-8.929-2.7-12.163-8.83-12.163-3.77 0-6.71 1.207-7.63 3.427-.14-1.737-.87-3.185-5.11-3.185-1.26 0-4.06.241-5.22.482l.39 6.034a18.44 18.44 0 0 1 1.59-.097c.68 0 .92.483.92 1.303v22.492h-2.56v6.178h13.8Zm-3.57-27.367c0-.386.05-.917.15-1.303.34-1.303 1.49-1.882 4.15-1.882 3.62 0 4.15 1.834 4.15 6.468 0 5.212-.72 6.853-4.83 6.853-1.54 0-2.51-.434-2.94-.868-.44-.387-.68-1.014-.68-2.124v-7.144Z"
      style="fill: #3c3c3b; fill-rule: nonzero"
      class="logotext"
      transform="translate(-1540.6 -922.84)"
    />
    <path
      d="M1703.87 957.596v-1.015h-1.48v-5.437h2.35c.8 0 1.18.259 1.44.677.45.717 1.29 3.047 1.93 4.501.36.796.83 1.334 2.35 1.334.3 0 .68-.02.99-.06l-.12-1.035c-.17.04-.35.06-.55.06-1.04 0-1.38-.399-1.78-1.315-.63-1.394-1.15-2.908-1.55-3.565-.2-.338-.52-.697-.95-.776 2.19-.419 3.14-1.693 3.14-3.545 0-1.614-.24-2.868-1.75-3.466-.76-.298-1.93-.418-3.49-.418h-4.7v1.016h1.48v12.029h-1.48v1.015h4.17Zm-1.48-7.468v-5.576h2.27c1.32 0 2.13.159 2.59.338.76.319 1.16 1.096 1.16 2.549 0 1.255-.54 1.972-1.3 2.311-.54.239-1.11.378-2.31.378h-2.41Z"
      style="fill: #3c3c3b; fill-rule: nonzero"
      class="logotext"
      transform="translate(-1540.6 -867.052)"
    />
    <path
      d="M1713.64 948.755h7.03c.04-.498.06-1.136.06-1.614 0-2.389-.69-3.764-3.78-3.764-3.01 0-4.46 1.335-4.46 5.218 0 3.426.94 5.138 4.4 5.138 1.73 0 2.77-.278 3.84-.916l-.41-.896c-.62.359-1.52.797-3.27.797-2.57 0-3.37-1.056-3.41-3.963Zm.02-.976c.06-2.251.82-3.446 3.21-3.446 2.35 0 2.77 1.056 2.77 2.749 0 .159 0 .557-.02.697h-5.96ZM1729.34 952.22c.02.737.32 1.414 1.61 1.414.3 0 .74-.02 1.04-.12l-.14-.956c-.26.06-.5.06-.72.06-.6 0-.78-.398-.78-1.135v-4.939c0-1.195-.1-1.852-.59-2.41-.5-.518-1.44-.757-3.01-.757-1.95 0-3.51.518-3.51 2.51v.318h1.12c0-.159 0-.298.02-.537.08-.837.66-1.295 2.35-1.295 1.55 0 2.07.398 2.29.857.16.318.2 1.095.2 1.613v1.195c-.54-.06-1.55-.06-2.27-.06-1.26 0-2.43.179-3.09.498-.82.438-1.21 1.115-1.21 2.29 0 1.494.63 2.967 3.44 2.967 1.44 0 2.81-.358 3.25-1.513Zm-.12-3.246v1.414c0 .458-.08.896-.22 1.195-.28.637-1.27 1.155-2.73 1.155-1.93 0-2.51-.936-2.51-1.992 0-.657.2-1.055.52-1.294.5-.379 1.43-.518 2.77-.518.69 0 1.73.02 2.17.04Z"
      style="fill: #3c3c3b; fill-rule: nonzero"
      class="logotext"
      transform="translate(-1540.6 -862.97)"
    />
    <path
      d="M1737.58 958.492v-.995h-1.41v-13.901h-2.53v.995h1.39v12.906h-1.35v.995h3.9Z"
      style="fill: #3c3c3b; fill-rule: nonzero"
      class="logotext"
      transform="translate(-1540.6 -867.948)"
    />
    <path
      d="M1743.44 957.696v-.995h-1.42v-8.903h-2.49v.996h1.36v7.907h-1.36v.995h3.91Zm-1.3-13.204c0-.697-.31-.896-.81-.896-.52 0-.86.279-.86.916 0 .697.36.897.86.897.52 0 .81-.259.81-.917Z"
      style="fill: #3c3c3b; fill-rule: nonzero"
      class="logotext"
      transform="translate(-1540.6 -867.152)"
    />
    <path
      d="M1749.75 949.272c1.71.359 1.87.897 1.87 1.633 0 1.016-.43 1.833-2.7 1.833-1.56 0-2.28-.379-2.53-.897-.16-.318-.22-.716-.26-1.055l-1.12.08c.02.378.08.776.2 1.155.42 1.195 1.53 1.712 3.63 1.712 2.84 0 3.92-1.015 3.92-2.887 0-1.633-.74-2.271-2.45-2.589l-1.79-.339c-1.6-.299-1.99-.597-1.99-1.713 0-1.135.45-1.852 2.64-1.852 1.28 0 1.86.339 2.06.817.15.378.17.697.19 1.015l1.12-.039c0-.518-.04-1.016-.22-1.454-.44-1.016-1.57-1.315-3.09-1.315-2.57 0-3.84.817-3.84 2.928 0 1.454.72 2.27 2.17 2.549l2.19.418Z"
      style="fill: #3c3c3b; fill-rule: nonzero"
      class="logotext"
      transform="translate(-1540.6 -862.97)"
    />
    <path
      d="M1758.41 957.696v-.995H1757v-8.903h-2.49v.996h1.36v7.907h-1.36v.995h3.9Zm-1.29-13.204c0-.697-.32-.896-.82-.896-.51 0-.85.279-.85.916 0 .697.36.897.85.897.52 0 .82-.259.82-.917Z"
      style="fill: #3c3c3b; fill-rule: nonzero"
      class="logotext"
      transform="translate(-1540.6 -867.152)"
    />
    <path
      d="M1761.4 948.755h7.03c.04-.498.06-1.136.06-1.614 0-2.389-.7-3.764-3.78-3.764-3.01 0-4.46 1.335-4.46 5.218 0 3.426.93 5.138 4.4 5.138 1.73 0 2.77-.278 3.84-.916l-.42-.896c-.61.359-1.51.797-3.26.797-2.57 0-3.37-1.056-3.41-3.963Zm.02-.976c.06-2.251.82-3.446 3.21-3.446 2.35 0 2.77 1.056 2.77 2.749 0 .159 0 .557-.02.697h-5.96Z"
      style="fill: #3c3c3b; fill-rule: nonzero"
      class="logotext"
      transform="translate(-1540.6 -862.97)"
    />
    <path
      d="M1775.04 953.772v-.995h-1.85v-6.015c0-.279.04-.617.1-.777.08-.179.2-.398.32-.517.3-.319.94-.697 1.83-.697.46 0 .96.079 1.32.199l.26-1.175c-.18-.08-.56-.199-1.18-.199-1.25 0-2.45.478-2.71 1.712 0-1.015-.24-1.533-1.39-1.533-.2 0-.88.02-1.14.079l.08.976c.22-.04.5-.04.66-.04.66 0 .72.419.72.897v7.09h-1.36v.995h4.34Z"
      style="fill: #3c3c3b; fill-rule: nonzero"
      class="logotext"
      transform="translate(-1540.6 -863.228)"
    />
    <path
      d="M1779.17 948.755h7.03c.04-.498.06-1.136.06-1.614 0-2.389-.7-3.764-3.79-3.764-3 0-4.46 1.335-4.46 5.218 0 3.426.94 5.138 4.4 5.138 1.74 0 2.77-.278 3.85-.916l-.42-.896c-.62.359-1.52.797-3.27.797-2.57 0-3.36-1.056-3.4-3.963Zm.02-.976c.06-2.251.81-3.446 3.2-3.446 2.35 0 2.77 1.056 2.77 2.749 0 .159 0 .557-.02.697h-5.95Z"
      style="fill: #3c3c3b; fill-rule: nonzero"
      class="logotext"
      transform="translate(-1540.6 -862.97)"
    />
    <path
      d="M1706.89 937.063c.02.897.36 1.414 1.62 1.414.3 0 .73-.039 1.01-.119l-.12-.956c-.2.04-.52.06-.73.06-.64 0-.76-.419-.76-1.155v-12.846h-2.53v.996h1.39v4.859c-.37-.717-1.27-1.095-2.61-1.095-3.1 0-4.64 1.215-4.64 5.397 0 3.664 1.36 4.959 4.21 4.959 1.39 0 2.69-.398 3.16-1.514Zm-.12-2.35c0 .538 0 .897-.08 1.235-.17.837-.93 1.613-2.84 1.613-2.21 0-3.17-1.235-3.17-4.063 0-2.987.76-4.242 3.43-4.242 1.61 0 2.37.478 2.58 1.395.06.239.08.637.08.956v3.106Z"
      style="fill: #3c3c3b; fill-rule: nonzero"
      class="logotext"
      transform="translate(-1540.6 -827.898)"
    />
    <path
      d="M1712.51 928.838h7.03c.04-.498.06-1.135.06-1.613 0-2.39-.7-3.764-3.78-3.764-3.01 0-4.47 1.334-4.47 5.218 0 3.425.94 5.138 4.41 5.138 1.73 0 2.76-.279 3.84-.916l-.42-.896c-.62.358-1.51.796-3.26.796-2.57 0-3.37-1.055-3.41-3.963Zm.02-.976c.06-2.25.82-3.445 3.21-3.445 2.35 0 2.76 1.055 2.76 2.748 0 .159 0 .558-.02.697h-5.95Z"
      style="fill: #3c3c3b; fill-rule: nonzero"
      class="logotext"
      transform="translate(-1540.6 -823.138)"
    />
    <path
      d="M1725.71 937.78v-.996h-1.41v-8.902h-2.49v.996h1.35v7.906h-1.35v.996h3.9Zm-1.29-13.204c0-.697-.32-.896-.82-.896-.52 0-.85.279-.85.916 0 .697.35.896.85.896.52 0 .82-.259.82-.916Z"
      style="fill: #3c3c3b; fill-rule: nonzero"
      class="logotext"
      transform="translate(-1540.6 -827.32)"
    />
    <path
      d="M1731.55 933.698v-.996h-1.4v-6.014c0-.359.02-.538.06-.717.06-.219.26-.438.4-.578.26-.219 1.24-.777 2.69-.777 1.22 0 1.73.259 1.95.777.12.259.18.876.18 1.474v5.258c0 .557.06.955.28 1.274.24.279.64.418 1.34.418.31 0 .85-.059 1.07-.099l-.08-.976c-.14.02-.48.06-.84.06-.51 0-.65-.259-.65-.797v-5.138c0-.797 0-1.554-.16-2.012-.28-.776-.88-1.294-2.69-1.294-1.33 0-3.23.398-3.61 1.633 0-1.016-.23-1.494-1.45-1.494-.3 0-.74.02-1.07.08l.08.976c.21-.04.49-.04.71-.04.6 0 .66.438.66.976v7.01h-1.35v.996h3.88Z"
      style="fill: #3c3c3b; fill-rule: nonzero"
      class="logotext"
      transform="translate(-1540.6 -823.238)"
    />
    <path
      d="M1741.11 928.838h7.03c.04-.498.06-1.135.06-1.613 0-2.39-.7-3.764-3.79-3.764-3 0-4.46 1.334-4.46 5.218 0 3.425.94 5.138 4.4 5.138 1.74 0 2.77-.279 3.85-.916l-.42-.896c-.62.358-1.51.796-3.27.796-2.57 0-3.36-1.055-3.4-3.963Zm.02-.976c.06-2.25.81-3.445 3.2-3.445 2.35 0 2.77 1.055 2.77 2.748 0 .159 0 .558-.02.697h-5.95Z"
      style="fill: #3c3c3b; fill-rule: nonzero"
      class="logotext"
      transform="translate(-1540.6 -823.138)"
    />
    <path
      d="M1759.09 937.74v-1.016h-1.47v-12.029h1.47v-1.015h-4.16v1.015h1.47v12.029h-1.47v1.016h4.16Z"
      style="fill: #3c3c3b; fill-rule: nonzero"
      class="logotext"
      transform="translate(-1540.6 -827.28)"
    />
    <path
      d="M1768.65 937.063c.02.897.36 1.414 1.61 1.414.3 0 .74-.039 1.02-.119l-.12-.956c-.2.04-.52.06-.74.06-.63 0-.75-.419-.75-1.155v-12.846h-2.53v.996h1.39v4.859c-.38-.717-1.27-1.095-2.61-1.095-3.1 0-4.64 1.215-4.64 5.397 0 3.664 1.36 4.959 4.2 4.959 1.4 0 2.69-.398 3.17-1.514Zm-.12-2.35c0 .538 0 .897-.08 1.235-.18.837-.93 1.613-2.85 1.613-2.21 0-3.16-1.235-3.16-4.063 0-2.987.75-4.242 3.42-4.242 1.62 0 2.37.478 2.59 1.395.06.239.08.637.08.956v3.106Z"
      style="fill: #3c3c3b; fill-rule: nonzero"
      class="logotext"
      transform="translate(-1540.6 -827.898)"
    />
    <path
      d="M1774.27 928.838h7.03c.04-.498.06-1.135.06-1.613 0-2.39-.7-3.764-3.79-3.764-3 0-4.46 1.334-4.46 5.218 0 3.425.94 5.138 4.4 5.138 1.74 0 2.77-.279 3.85-.916l-.42-.896c-.62.358-1.51.796-3.27.796-2.57 0-3.36-1.055-3.4-3.963Zm.02-.976c.06-2.25.81-3.445 3.2-3.445 2.35 0 2.77 1.055 2.77 2.748 0 .159 0 .558-.02.697h-5.95ZM1784.56 928.838h7.03c.04-.498.06-1.135.06-1.613 0-2.39-.69-3.764-3.78-3.764-3.01 0-4.46 1.334-4.46 5.218 0 3.425.93 5.138 4.4 5.138 1.73 0 2.77-.279 3.84-.916l-.41-.896c-.62.358-1.52.796-3.27.796-2.57 0-3.37-1.055-3.41-3.963Zm.02-.976c.06-2.25.82-3.445 3.21-3.445 2.35 0 2.77 1.055 2.77 2.748 0 .159 0 .558-.02.697h-5.96Z"
      style="fill: #3c3c3b; fill-rule: nonzero"
      class="logotext"
      transform="translate(-1540.6 -823.138)"
    />
    <path
      d="M1558.12 973.556a4.341 4.341 0 0 1 0-4.353c4.61-7.995 20.6-35.693 25.22-43.683a4.347 4.347 0 0 1 3.77-2.18h50.45c1.55 0 2.99.83 3.77 2.18 4.61 7.99 20.6 35.688 25.22 43.683a4.341 4.341 0 0 1 0 4.353c-4.62 7.996-20.61 35.694-25.22 43.684a4.36 4.36 0 0 1-3.77 2.18h-50.45c-1.56 0-2.99-.83-3.77-2.18-4.62-7.99-20.61-35.688-25.22-43.684Z"
      style="fill: #6b3e91"
      transform="translate(-1540.6 -908.62)"
    />
    <path
      d="M1647.95 909.974a2.916 2.916 0 0 1 1.77-1.355 2.89 2.89 0 0 1 2.2.291c1.07.617 2.32 1.335 3.38 1.952a2.908 2.908 0 0 1 1.07 3.968c-1.79 3.107-4.81 8.355-6.57 11.417a4.827 4.827 0 0 1-4.18 2.421h-45.7a2.907 2.907 0 0 1-2.91-2.908v-4.033c0-1.606 1.3-2.907 2.91-2.907h40.14c1.72 0 3.32-.92 4.18-2.414 1.09-1.892 2.6-4.515 3.71-6.432Z"
      style="fill: #2c2f88"
      transform="translate(-1540.6 -802.948)"
    />
    <path
      d="M1554.42 957.119a2.9 2.9 0 0 1 3.97-1.068l3.35 1.933a2.9 2.9 0 0 1 1.06 3.972c-2.59 4.494-7.98 13.828-10.46 18.132a4.52 4.52 0 0 0 0 4.527c1.16 2.02 2.87 4.975 4.08 7.069.8 1.39.32 3.168-1.07 3.971a840.14 840.14 0 0 1-3.33 1.923c-.67.386-1.46.491-2.21.29-.74-.2-1.38-.688-1.76-1.357-1.86-3.237-5.07-8.801-6.85-11.903a4.547 4.547 0 0 1 0-4.515c2.95-5.124 10.13-17.605 13.22-22.974Z"
      style="fill: #ec6608"
      transform="translate(-1540.6 -919.49)"
    />
    <path
      d="M1674.73 1005.85c.8 1.39.33 3.16-1.06 3.96-1.06.61-2.28 1.32-3.34 1.93-.67.39-1.46.49-2.21.29-.74-.2-1.37-.69-1.76-1.35l-12.85-22.265a2.898 2.898 0 0 1 1.06-3.97c1.07-.62 2.31-1.33 3.37-1.95.67-.38 1.46-.49 2.21-.29.74.2 1.38.69 1.76 1.36 3 5.21 9.83 17.085 12.82 22.285Z"
      style="fill: #d35378"
      transform="translate(-1540.6 -960.095)"
    />
    <path
      d="M1609.19 1031.23a2.915 2.915 0 0 1-2.91 2.91h-18.35a2.906 2.906 0 0 1-2.9-2.91v-4.03a2.906 2.906 0 0 1 2.9-2.91h18.35a2.915 2.915 0 0 1 2.91 2.91v4.03Z"
      style="fill: #a35494"
      transform="translate(-1540.6 -1024.29)"
    />
  </svg>
</template>

<script type="text/babel">
export default {
  name: "LogoTeStUp",
};
</script>

<style lang="scss"></style>

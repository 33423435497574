<template>
  <div class="home">
    <div class="container content">
      <div class="columns is-multiline mt-4">
        <div class="column is-6">
          <img class="testupdoor" :src="require(`@/assets/meettestup.svg`)" />
        </div>
        <div class="column is-6">
          <h1
            class="is-size-2 is-size-3-mobile mt-6 mb-1 has-text-white has-text-weight-bolder"
          >
            Moin moin bei TeStUp,
          </h1>
          <p
            class="is-size-4 is-size-5-mobile has-text-white has-text-weight-light mt-1"
          >
            dem StartUp-Village für technologische Produkte der Hochschule
            Flensburg.
          </p>
          <p class="is-size-6">
            Mit dem Projekt TeStUp will die Hochschule Flensburg Gründungen im
            technischen Bereich stärken. Dazu stehen für dich verschiedene
            Labore zur Verfügung, in denen wir dich als potenzieller Gründer
            oder potenzielle Gründerin entlang deiner Produktentwicklung
            unterstützen.
          </p>
          <p class="is-size-6">
            Wir möchten Ideen realisieren, die an der Hochschule entstehen. Und
            wie das genau funktioniert, das erklären wir dir auf dieser Website.
          </p>
        </div>
      </div>
      <div class="columns is-multiline mt-6">
        <div class="column is-12">
          <h2
            class="is-size-3 is-size-4-mobile mt-6 mb-1 has-text-white has-text-weight-bolder has-text-centered"
          >
            Wie können wir unterstützen?
          </h2>
        </div>
        <div class="column is-4 has-text-centered mb-5">
          <a
            href="https://realisiere-deine-idee.de/tools-technik"
            class="is-size-6 button is-medium is-primary is-outlined is-inverted"
            ><span class="icon is-small"
              ><i class="mdi mdi-link-variant"></i
            ></span>
            &nbsp;&nbsp;&nbsp;Tools & Technik</a
          >
        </div>
        <div class="column is-4 has-text-centered mb-5">
          <a
            href="https://realisiere-deine-idee.de/mentoring-beratung"
            class="is-size-6 button is-medium is-primary is-outlined is-inverted"
            ><span class="icon is-small"
              ><i class="mdi mdi-link-variant"></i
            ></span>
            &nbsp;&nbsp;&nbsp;Mentoring & Beratung</a
          >
        </div>
        <div class="column is-4 has-text-centered mb-5">
          <a
            href="https://realisiere-deine-idee.de/finanzielle-foerderung"
            class="is-size-6 button is-medium is-primary is-outlined is-inverted"
            ><span class="icon is-small"
              ><i class="mdi mdi-link-variant"></i
            ></span>
            &nbsp;&nbsp;&nbsp;finanzielle Förderung</a
          >
        </div>
      </div>
      <div class="columns is-multiline mt-8">
        <div class="column is-5 mt-6 mb-6">
          <h2
            class="is-size-3 is-size-4-mobile mt-6 mb-1 has-text-white has-text-weight-bolder"
          >
            So arbeiten wir,
          </h2>
          <p
            class="is-size-4 is-size-5-mobile has-text-white has-text-weight-light mt-1"
          >
            um deine Idee realisieren zu können.
          </p>
          <p class="is-size-6">
            Bei uns steht die Idee oder das Gründungsvorhaben im Mittelpunkt.
            Über ein System aus Mentoring, Gründungsunterstützung aus dem Dock1,
            Schulungen im Opportunity Space und ggf. einer finaziellen Förderung
            über Kickstart bieten wir dabei Unterstützung auf verschiedenen
            Ebenen.
          </p>
          <p class="is-size-6">
            Dein Produkt kann dabei in unseren Laboren entstehen. Hier haben wir
            für jeden Zyklus einer Idee verschiedene Möglichkeiten, um dich und
            dein Produkt weiter nach vorne zu bringen.
          </p>
          <p class="is-size-6">
            <a
              href="https://realisiere-deine-idee.de/orientierung"
              class="is-size-6 button is-medium is-primary is-outlined is-inverted"
              ><span class="icon is-small"
                ><i class="mdi mdi-link-variant"></i
              ></span>
              &nbsp;&nbsp;&nbsp;Der Weg durch TeStUp</a
            >
          </p>
        </div>
        <div class="column is-7">
          <img class="testupweg" :src="require(`@/assets/Arbeitsweise.svg`)" />
        </div>
        <div class="column is-12 mt-8">
          <h2
            class="is-size-3 is-size-4-mobile mt-6 mb-1 has-text-white has-text-weight-bolder has-text-centered"
          >
            Unsere Labore
          </h2>
          <div class="level is-mobile mt-6 mb-6 lablogos">
            <div class="level-item has-text-centered">
              <a href="https://realisiere-deine-idee.de/techshop">
                <img
                  class="ll-techshop"
                  :src="require(`@/assets/logos/TechShop-Logo1k.svg`)"
                />
              </a>
            </div>
            <div class="level-item has-text-centered">
              <a href="https://realisiere-deine-idee.de/ideenreich">
                <img
                  class="ll-ideenreich"
                  :src="require(`@/assets/logos/Ideenreich-LogoQuer1k.svg`)"
                />
              </a>
            </div>
            <div class="level-item has-text-centered">
              <a href="https://realisiere-deine-idee.de/e3lab">
                <img
                  class="ll-e3lab"
                  :src="require(`@/assets/logos/E3Lab-Logo1k.svg`)"
                />
              </a>
            </div>
            <div class="level-item has-text-centered">
              <a href="https://realisiere-deine-idee.de/designlab">
                <img
                  class="ll-designlab"
                  :src="require(`@/assets/logos/DesignLab-Logo1k.svg`)"
                />
              </a>
            </div>
          </div>
          <div class="level is-mobile mt-6 mb-6 lablogos secondline">
            <div class="level-item has-text-centered">
              <a href="https://realisiere-deine-idee.de/opportunityspace">
                <img
                  class="ll-opportunityspace"
                  :src="require(`@/assets/logos/OpportunitySpace-Logo1k.svg`)"
                />
              </a>
            </div>
            <div class="level-item has-text-centered">
              <a href="https://realisiere-deine-idee.de/usabilitylab">
                <img
                  class="ll-usabilitylab"
                  :src="require(`@/assets/logos/UsabilityLab-Logo1k.svg`)"
                />
              </a>
            </div>
            <div class="level-item has-text-centered">
              <a href="https://realisiere-deine-idee.de/softwarefactory">
                <img
                  class="ll-softwarefactory"
                  :src="require(`@/assets/logos/SoftwareFactory-Logo1k.svg`)"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="column is-12 mt-8">
          <h2
            class="is-size-3 is-size-4-mobile mt-6 mb-1 has-text-white has-text-weight-bolder has-text-centered"
          >
            Interessiert?
          </h2>
        </div>
        <div class="column is-6-desktop is-12-tablet has-text-centered">
          <a
            href="https://realisiere-deine-idee.de/wer-kann-mitmachen"
            class="is-size-6 button is-medium is-primary is-outlined is-inverted"
            ><span class="icon is-small"
              ><i class="mdi mdi-link-variant"></i
            ></span>
            &nbsp;&nbsp;&nbsp;Wer kann mitmachen?</a
          >
        </div>
        <div class="column is-6-desktop is-12-tablet has-text-centered mb-6">
          <a
            href="https://realisiere-deine-idee.de/wie-mitmachen"
            class="is-size-6 button is-medium is-primary is-outlined is-inverted"
            ><span class="icon is-small"
              ><i class="mdi mdi-link-variant"></i
            ></span>
            &nbsp;&nbsp;&nbsp;Wie kann ich mitmachen?</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
};
</script>

<style lang="scss">
.mt-8 {
  margin-top: 7rem;
}

.lablogos a img {
  height: 5.5rem;
  max-height: 100%;
  display: block;
  transition: all 0.3s ease;
  &.ll-ideenreich {
    height: 2.75rem;
  }
  &.ll-designlab {
    height: 3.75rem;
  }
  &.ll-usabilitylab {
    height: 2.25rem;
  }
}
.lablogos a:hover img {
  opacity: 0.5;
}
.testupweg {
  max-width: 80%;
  text-align: center;
  margin-top: 14rem;
  margin-bottom: 3rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.testupdoor {
  width: 17rem;
  max-width: 100%;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 3rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
//mobile, mobile image, 768px --> was min-width 521
@media screen and (min-width: 1px) and (max-width: $desktop - 1px) {
  .testupweg {
    max-width: 100%;
    margin-top: 1rem;
  }
  .level.is-mobile.lablogos {
    margin-bottom: 0rem !important;
    &.secondline {
      margin-top: 0rem !important;
    }
    flex-wrap: wrap;
    .level-item a img {
      margin-bottom: 4rem;
    }
  }
}
</style>

<template>
  <div class="newsmodal">
    <b-modal
      v-model="ismodal"
      :width="640"
      has-modal-card
      scroll="keep"
      @close="onClose"
    >
      <div class="card">
        <div class="card-image">
          <b-image
            v-if="modaldata.properties.NewsBild.files.length != 0"
            :src="modaldata.properties.NewsBild.files[0].file.url"
            :placeholder="require(`@/assets/ImagePlaceholder.png`)"
            ratio="4by4"
          >
          </b-image>
        </div>
        <div class="card-content">
          <div class="content">
            <h2
              class="is-size-3 is-size-4-mobile mb-0 has-text-primary"
              v-if="modaldata.properties.Headline.rich_text.length != 0"
            >
              {{ modaldata.properties.Headline.rich_text[0].plain_text }}
            </h2>

            <p
              class="is-size-6 eventtext"
              v-if="modaldata.properties.Newstext.rich_text.length != 0"
            >
              {{ modaldata.properties.Newstext.rich_text[0].plain_text }}
            </p>
            <p class="is-size-6">
              <a
                class="is-size-6 button is-medium is-primary is-outlined mr-5 v"
                :href="modaldata.properties.mehrInfos.url"
                target="_self"
                v-if="modaldata.properties.mehrInfos.url"
                ><b-icon icon="link-variant" size="is-small"> </b-icon>
                &nbsp;&nbsp;&nbsp;mehr Informationen</a
              >
            </p>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script type="text/babel">
export default {
  name: "NewsModal",
  components: {},
  data() {
    return {
      ismodal: true,
    };
  },
  props: {
    modaldata: [Array, Object],
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
.eventtext {
  white-space: break-spaces;
  width: 500px;
  max-width: 100%;
}
</style>

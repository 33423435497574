<template>
  <div class="column is-6">
    <div class="block">
      <h3 class="is-size-4">Ankündigungen & News</h3>
    </div>
    <div class="columns is-multiline mb-6 is-desktop">
      <div
        class="column is-12 is-6-desktop"
        v-for="news in data.results"
        :key="news.id"
      >
        <div class="block footerblock">
          <a @click="openModal(news)" class="is-size-7 has-text-weight-light">
            <span
              class="is-size-6 has-text-weight-bold"
              v-if="news.properties.Headline.rich_text.length != 0"
            >
              {{ news.properties.Headline.rich_text[0].plain_text }}</span
            ><br />
            <span v-if="news.properties.Beschreibung.rich_text.length != 0">
              {{ news.properties.Beschreibung.rich_text[0].plain_text }}
            </span>
          </a>
        </div>
      </div>
      <div class="column is-12 is-6-desktop" v-if="isLoadingNews">
        <div class="block footerblock">
          <b-skeleton height="17px" width="50%"></b-skeleton>
          <b-skeleton height="14px" width="85%"></b-skeleton>
          <b-skeleton height="14px" width="75%"></b-skeleton>
        </div>
      </div>
      <div class="column is-12 is-6-desktop" v-if="isLoadingNews">
        <div class="block footerblock">
          <b-skeleton height="17px" width="70%"></b-skeleton>
          <b-skeleton height="14px" width="95%"></b-skeleton>
          <b-skeleton height="14px" width="80%"></b-skeleton>
        </div>
      </div>
      <div class="column is-12 is-6-desktop" v-if="isLoadingNews">
        <div class="block footerblock">
          <b-skeleton height="17px" width="45%"></b-skeleton>
          <b-skeleton height="14px" width="90%"></b-skeleton>
          <b-skeleton height="14px" width="67%"></b-skeleton>
        </div>
      </div>
      <div class="column is-12 is-6-desktop" v-if="isLoadingNews">
        <div class="block footerblock">
          <b-skeleton height="17px" width="80%"></b-skeleton>
          <b-skeleton height="14px" width="93%"></b-skeleton>
          <b-skeleton height="14px" width="82%"></b-skeleton>
        </div>
      </div>
    </div>
    <div class="block newsletterheadline">
      <h3 class="is-size-4">Immer auf dem Laufenden bleiben…</h3>
    </div>
    <Newsletter newsletterteaser="…mit unserem Newsletter." />
  </div>
</template>

<script>
import Newsletter from "@/components/layout/Newsletter.vue";
import axios from "axios";

export default {
  name: "News",
  components: {
    Newsletter,
  },
  data() {
    return {
      data: [],
      isLoadingNews: true,
      isErrorNEws: false,
      NotionNewsID: "c6efd1939eba45c4a3f6c3a925d13136",
      notionpagesize: 4,
    };
  },
  methods: {
    async getData() {
      try {
        const res = await axios.post(
          ".netlify/functions/readnews",
          JSON.stringify({
            PageID: this.NotionNewsID,
            PageSize: this.notionpagesize,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        if (res.status === 200) {
          this.data = res.data;
          this.isLoadingNews = false;
        } else {
          //could not get Data
          this.isLoadingNews = false;
          this.isErrorNEws = true;
        }
      } catch (error) {
        //console.log("ERROR:", error);
        this.isLoadingNews = false;
        this.isErrorNEws = true;
      }
    },
    openModal(data) {
      this.$emit("newsmodaldata", data);
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss">
.newsletterheadline {
  margin-bottom: -1rem !important;
}
</style>

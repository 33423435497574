<template>
  <div class="column is-6 is-5-desktop is-offset-1-desktop">
    <div class="block">
      <h3 class="is-size-4 eventheadline">Kommende Veranstaltungen</h3>
    </div>
    <div
      class="block footerblock"
      v-for="event in data.results"
      :key="event.id"
    >
      <a @click="openModal(event)" class="is-size-7 has-text-weight-light">
        <span
          class="is-size-6 has-text-weight-bold"
          v-if="event.properties.WebsiteHeadline.rich_text.length != 0"
        >
          {{ event.properties.WebsiteHeadline.rich_text[0].plain_text }}
        </span>
        <br />
        <span
          v-if="
            event.properties.Datum.date.end == null &&
            event.properties.Datum.date.start.length == 10
          "
        >
          <!-- nur Startdatum, keine Zeit -->
          {{ event.properties.Datum.date.start | moment("DD.MM.YYYY") }}
        </span>
        <span
          v-else-if="
            event.properties.Datum.date.end == null &&
            event.properties.Datum.date.start.length != 10
          "
        >
          <!-- nur Startdatum mit Startzeit -->
          {{ event.properties.Datum.date.start | moment("DD.MM.YYYY") }} ab
          {{ event.properties.Datum.date.start | moment("HH:mm") }}
        </span>
        <span
          v-else-if="
            event.properties.Datum.date.end != null &&
            event.properties.Datum.date.start.length != 10 &&
            event.properties.Datum.date.start.slice(0, -19) ==
              event.properties.Datum.date.end.slice(0, -19)
          "
        >
          <!-- nur Startdatum mit Startzeit und Endzeit -->
          {{ event.properties.Datum.date.start | moment("DD.MM.YYYY") }} von
          {{ event.properties.Datum.date.start | moment("HH:mm") }} bis
          {{ event.properties.Datum.date.end | moment("HH:mm") }}
        </span>
        <span
          v-if="
            event.properties.Datum.date.end != null &&
            event.properties.Datum.date.end.length == 10
          "
        >
          <!-- Startdatum, keine Zeit + Enddatum, keine Zeit -->
          {{ event.properties.Datum.date.start | moment("DD.MM.YYYY") }} bis
          {{ event.properties.Datum.date.end | moment("DD.MM.YYYY") }}
        </span>
        <span
          v-if="
            event.properties.Datum.date.end != null &&
            event.properties.Datum.date.end.length != 10 &&
            event.properties.Datum.date.start.slice(0, -19) !=
              event.properties.Datum.date.end.slice(0, -19)
          "
        >
          <!-- Startdatum mit Zeit + Enddatum mit Zeit -->
          {{ event.properties.Datum.date.start | moment("DD.MM.YYYY") }},
          {{ event.properties.Datum.date.start | moment("HH:mm") }} bis
          {{ event.properties.Datum.date.end | moment("DD.MM.YYYY") }},
          {{ event.properties.Datum.date.end | moment("HH:mm") }}
        </span>
        <span v-if="event.properties.WebsiteOrt.rich_text.length != 0">
          | {{ event.properties.WebsiteOrt.rich_text[0].plain_text }}
        </span>
      </a>
    </div>
    <div class="block footerblock" v-if="isLoadingEvent">
      <b-skeleton height="17px" width="50%"></b-skeleton>
      <b-skeleton height="14px" width="35%"></b-skeleton>
    </div>
    <div class="block footerblock" v-if="isLoadingEvent">
      <b-skeleton height="17px" width="40%"></b-skeleton>
      <b-skeleton height="14px" width="45%"></b-skeleton>
    </div>
    <div class="block footerblock" v-if="isLoadingEvent">
      <b-skeleton height="17px" width="60%"></b-skeleton>
      <b-skeleton height="14px" width="25%"></b-skeleton>
    </div>
    <div class="block footerblock" v-if="isLoadingEvent">
      <b-skeleton height="17px" width="55%"></b-skeleton>
      <b-skeleton height="14px" width="50%"></b-skeleton>
    </div>
    <div class="block footerblock" v-if="isLoadingEvent">
      <b-skeleton height="17px" width="30%"></b-skeleton>
      <b-skeleton height="14px" width="25%"></b-skeleton>
    </div>
    <div class="block footerreadmore">
      <a
        href="https://realisiere-deine-idee.de/uebersicht"
        class="is-size-6 has-text-weight-light"
      >
        <b-icon icon="arrow-right-thick" size="is-small"> </b-icon>
        weitere Veranstaltungen
      </a>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Events",
  components: {},
  data() {
    return {
      data: [],
      isLoadingEvent: true,
      isErrorEvent: false,
      NotionEventID1: "598bbbda0def4d92ad90fce19e156d63",
      NotionEventID2: "05dbeb8cbd2f459ea29300502039f3f1",
      notionpagesize: 5,
    };
  },
  methods: {
    async getData() {
      try {
        const res = await axios.post(
          ".netlify/functions/readevents",
          JSON.stringify({
            PageID1: this.NotionEventID1,
            PageID2: this.NotionEventID2,
            PageSize: this.notionpagesize,
          }),
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        if (res.status === 200) {
          this.data = res.data;
          this.isLoadingEvent = false;
        } else {
          //could not get Data
          this.isLoadingEvent = false;
          this.isErrorEvent = true;
        }
      } catch (error) {
        //console.log("ERROR:", error);
        this.isLoadingEvent = false;
        this.isErrorEvent = true;
      }
    },
    openModal(data) {
      this.$emit("eventmodaldata", data);
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss"></style>

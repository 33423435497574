<template>
  <div class="block">
    <p class="has-text-white is-size-6 has-text-weight-light mb-5" v-if="!send">
      {{ newsletterteaser }}
    </p>
    <ValidationObserver ref="observer" v-slot="{ passes }">
      <ValidationProvider
        rules="required|email"
        name="email"
        v-slot="{ errors, valid }"
      >
        <b-field
          grouped
          :type="{ 'is-white': errors[0], 'is-success': valid }"
          :message="errors"
          v-if="!send"
          class="is-success"
        >
          <b-input
            placeholder="Email"
            v-model="email"
            type="email"
            icon="email"
            size="is-large"
          >
          </b-input>
          <p class="control">
            <b-button
              type="is-primary"
              size="is-large"
              inverted
              outlined
              label="abonnieren"
              @click="passes(requestnewsletter)"
            />
          </p>
        </b-field>
      </ValidationProvider>
    </ValidationObserver>
    <p v-if="!send">
      <small>
        Mit dem Absenden werden die Datenschutzbedingungen anerkannt.
      </small>
    </p>
    <div class="notification is-info spacetop" v-if="send">
      <strong>Danke!</strong> Wir haben gerade eine Mail versendet, hier muss
      noch einmal die verwendete E-Mail Adresse bestätigt werden. Die Zustellung
      kann ein wenig dauern. Schaue bitte auch ggf. im SPAM-Ordner nach.
    </div>
    <div class="notification is-info spacetop" v-if="nosend">
      <strong>Vedammt, hier klappt etwas nicht!</strong> Die E-Mail konnte
      gerade nicht versendet werden. Probiere es bitte später noch einmal.
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required, email } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("required", {
  ...required,
  message: "Dieses Feld muss ausgefüllt werden.",
});

extend("email", {
  ...email,
  message: "Bitte trage eine gültige E-Mail-Adresse ein.",
});

export default {
  name: "Newsletter",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    newsletterteaser: String,
  },
  data() {
    return {
      email: "",
      send: false,
      nosend: false,
    };
  },
  methods: {
    requestnewsletter() {
      this.sendverification();
    },

    async sendverification() {
      try {
        const res = await axios.post(
          "/.netlify/functions/newsletterrequest",
          {
            Email: this.email,
          },
          {
            headers: { "Content-Type": "application/json" },
          }
        );
        if (res.status === 200) {
          this.send = true;
          //console.log("Mail send!");
        } else {
          //this.isLoading = false;
          this.nosend = true;
          //console.log("Could not send Mail.");
        }
      } catch (error) {
        this.nosend = true;
        //console.log("ERROR:", error);
      }
    },
  },
};
</script>

<style lang="scss"></style>

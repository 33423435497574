import Vue from 'vue';
import VueRouter from 'vue-router';
import '@mdi/font/css/materialdesignicons.css';
import Buefy from 'buefy';
import VueMeta from 'vue-meta';
import Home from '../views/Home.vue';

Vue.use(VueRouter);
Vue.use(Buefy);
Vue.use(VueMeta);

//add new routes also to Sitemap via vue.config.js!!!

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home,
	},

	// Angebot
	{
		path: '/kickstart',
		name: 'Kickstart',
		component: () => import('../views/Angebot/Kickstart.vue'),
	},
	{
		path: '/mentoring',
		name: 'Mentoring',
		component: () => import('../views/Angebot/Mentoring.vue'),
	},
	{
		path: '/opportunityspace',
		name: 'Opportunity Space',
		component: () => import('../views/Angebot/Opportunityspace.vue'),
	},
	{
		path: '/designlab',
		name: 'DesignLab',
		component: () => import('../views/Angebot/designlab.vue'),
	},
	{
		path: '/softwarefactory',
		name: 'Software Factory',
		component: () => import('../views/Angebot/softwarefactory.vue'),
	},
	{
		path: '/e3lab',
		name: 'E3 Lab',
		component: () => import('../views/Angebot/e3lab.vue'),
	},
	{
		path: '/techshop',
		name: 'TechShop',
		component: () => import('../views/Angebot/techshop.vue'),
	},
	{
		path: '/usabilitylab',
		name: 'Usability Lab',
		component: () => import('../views/Angebot/usabilitylab.vue'),
	},
	{
		path: '/ideenreich',
		name: 'IDEENREICH',
		component: () => import('../views/Angebot/Ideenreich.vue'),
	},
	{
		path: '/studieren',
		name: 'studieren',
		component: () => import('../views/Angebot/studieren.vue'),
	},

	// Mitmachen
	{
		path: '/wer-kann-mitmachen',
		name: 'Wer kann mitmachen?',
		component: () => import('../views/Mitmachen/wer-kann-mitmachen.vue'),
	},
	{
		path: '/wie-mitmachen',
		name: 'Wie kann ich mitmachen?',
		component: () => import('../views/Mitmachen/wie-mitmachen.vue'),
	},
	{
		path: '/mentoring-beratung',
		name: 'Mentoring & Beratung',
		component: () => import('../views/Mitmachen/Mentoring-Beratung.vue'),
	},
	{
		path: '/tools-technik',
		name: 'Tools & Technik',
		component: () => import('../views/Mitmachen/Tools-Technik.vue'),
	},
	{
		path: '/methoden',
		name: 'Methoden',
		component: () => import('../views/Mitmachen/Methoden.vue'),
	},
	{
		path: '/finanzielle-foerderung',
		name: 'Finanzielle Förderung',
		component: () => import('../views/Mitmachen/FinanzielleFoerderung.vue'),
	},

	// Veranstaltungen
	{
		path: '/uebersicht',
		name: 'Uebersicht',
		component: () => import('../views/Veranstaltungen/Uebersicht.vue'),
	},
	{
		path: '/openlabs',
		name: 'Open Labs',
		component: () => import('../views/Veranstaltungen/OpenLabs.vue'),
	},
	{
		path: '/bbq',
		name: 'BBQ',
		component: () => import('../views/Veranstaltungen/bbq.vue'),
	},
	{
		path: '/techwednesday',
		name: 'TechWednesday',
		component: () => import('../views/Veranstaltungen/TechWednesday.vue'),
	},
	{
		path: '/help2kickstart',
		name: 'help2kickstart',
		component: () => import('../views/Veranstaltungen/help2kickstart.vue'),
	},
	{
		path: '/itstimetotestup',
		name: 'itstimetotestup',
		component: () => import('../views/Veranstaltungen/itstimetotestup.vue'),
	},
	{
		path: '/www',
		name: 'Was wäre wenn…',
		component: () => import('../views/Veranstaltungen/WasWaereWenn.vue'),
	},
	{
		path: '/testupbbqonstage',
		name: 'TeStUp BBQ on Stage',
		component: () => import('../views/Veranstaltungen/TeStUpBBQonStage.vue'),
	},
	{
		path: '/testupbbqonstage-bewerbung',
		name: 'TeStUp BBQ on Stage Bewerbung',
		component: () => import('../views/Veranstaltungen/TeStUpBBQonStageBewerbung.vue'),
	},
	{
		path: '/studierenprobieren',
		name: 'Studieren probieren',
		component: () => import('../views/Veranstaltungen/studierenprobieren.vue'),
	},
	// Weg durch TeStUp
	{
		path: '/orientierung',
		name: 'Orientierung',
		component: () => import('../views/WegDurchtestup/Orientierung.vue'),
	},
	{
		path: '/ideenzyklus',
		name: 'Ideenzyklus',
		component: () => import('../views/WegDurchtestup/Ideenzyklus.vue'),
	},
	{
		path: '/konzeptzyklus',
		name: 'Konzeptzyklus',
		component: () => import('../views/WegDurchtestup/Konzeptzyklus.vue'),
	},
	{
		path: '/entwicklungszyklus',
		name: 'Entwicklungszyklus',
		component: () => import('../views/WegDurchtestup/Entwicklungszyklus.vue'),
	},
	{
		path: '/markteinfuehrungszyklus',
		name: 'Markteinführungszyklus',
		component: () => import('../views/WegDurchtestup/Markteinfuehrungszyklus.vue'),
	},

	// Über uns
	{
		path: '/beispiele',
		name: 'Beispiele',
		component: () => import('../views/UeberUns/Beispiele.vue'),
	},
	{
		path: '/erfolge',
		name: 'Erfolge',
		component: () => import('../views/UeberUns/Erfolge.vue'),
	},
	{
		path: '/startuplab',
		name: 'StartUpLab',
		component: () => import('../views/UeberUns/StartUpLab.vue'),
	},
	{
		path: '/team',
		name: 'Team',
		component: () => import('../views/UeberUns/Team.vue'),
	},
	{
		path: '/partner',
		name: 'Partner',
		component: () => import('../views/UeberUns/Partner.vue'),
	},
	{
		path: '/entrepreneurship-hs-flensburg',
		name: 'Entrepreneurship HS Flensburg',
		component: () => import('../views/UeberUns/Entrepreneurship.vue'),
	},
	{
		path: '/lehre',
		name: 'TeStUp in der Lehre',
		component: () => import('../views/UeberUns/Lehre.vue'),
	},

	// Footer
	{
		path: '/impressum',
		name: 'Impressum',
		component: () => import('../views/Footer/Impressum.vue'),
	},
	{
		path: '/datenschutz',
		name: 'Datenschutz',
		component: () => import('../views/Footer/Datenschutz.vue'),
	},
	{
		path: '/lageplan',
		name: 'Lageplan',
		component: () => import('../views/Footer/Lageplan.vue'),
	},
	{
		path: '/kontakt',
		name: 'Kontakt',
		component: () => import('../views/Footer/Kontakt.vue'),
	},

	// Sonstiges
	{
		path: '/newsletter-step-2',
		name: 'Newsletter Schritt 2',
		component: () => import('../views/response/Newsletteroptin.vue'),
	},

	// Old Nav
	{
		path: '/todo',
		name: 'ToDo',
		component: () => import('../views/todo.vue'),
	},
	{
		path: '/elemente',
		name: 'Elemente',
		component: () => import('../views/Elemente.vue'),
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0 };
		}
	},
	linkActiveClass: 'is-active',
});

export default router;

<template>
  <div class="eventmodal">
    <b-modal
      v-model="ismodal"
      :width="640"
      has-modal-card
      scroll="keep"
      @close="onClose"
    >
      <div class="card">
        <div class="card-image">
          <b-image
            v-if="modaldata.properties.WebsiteImage.files.length != 0"
            :src="modaldata.properties.WebsiteImage.files[0].file.url"
            :placeholder="require(`@/assets/ImagePlaceholder.png`)"
            ratio="4by4"
          >
          </b-image>
        </div>
        <div class="card-content">
          <div class="content">
            <h2
              class="is-size-3 is-size-4-mobile mb-0 has-text-primary"
              v-if="modaldata.properties.WebsiteHeadline.rich_text.length != 0"
            >
              {{ modaldata.properties.WebsiteHeadline.rich_text[0].plain_text }}
            </h2>
            <p>
              <span
                class="is-size-6"
                v-if="
                  modaldata.properties.Datum.date.end == null &&
                  modaldata.properties.Datum.date.start.length == 10
                "
              >
                <!-- nur Startdatum, keine Zeit -->
                {{
                  modaldata.properties.Datum.date.start | moment("DD.MM.YYYY")
                }}
              </span>
              <span
                class="is-size-6"
                v-else-if="
                  modaldata.properties.Datum.date.end == null &&
                  modaldata.properties.Datum.date.start.length != 10
                "
              >
                <!-- nur Startdatum mit Startzeit -->
                {{
                  modaldata.properties.Datum.date.start | moment("DD.MM.YYYY")
                }}
                ab
                {{ modaldata.properties.Datum.date.start | moment("HH:mm") }}
              </span>
              <span
                class="is-size-6"
                v-else-if="
                  modaldata.properties.Datum.date.end != null &&
                  modaldata.properties.Datum.date.start.length != 10 &&
                  modaldata.properties.Datum.date.start.slice(0, -19) ==
                    modaldata.properties.Datum.date.end.slice(0, -19)
                "
              >
                <!-- nur Startdatum mit Startzeit und Endzeit -->
                {{
                  modaldata.properties.Datum.date.start | moment("DD.MM.YYYY")
                }}
                von
                {{ modaldata.properties.Datum.date.start | moment("HH:mm") }}
                bis
                {{ modaldata.properties.Datum.date.end | moment("HH:mm") }}
              </span>
              <span
                class="is-size-6"
                v-if="
                  modaldata.properties.Datum.date.end != null &&
                  modaldata.properties.Datum.date.end.length == 10
                "
              >
                <!-- Startdatum, keine Zeit + Enddatum, keine Zeit -->
                {{
                  modaldata.properties.Datum.date.start | moment("DD.MM.YYYY")
                }}
                bis
                {{ modaldata.properties.Datum.date.end | moment("DD.MM.YYYY") }}
              </span>
              <span
                class="is-size-6"
                v-if="
                  modaldata.properties.Datum.date.end != null &&
                  modaldata.properties.Datum.date.end.length != 10 &&
                  modaldata.properties.Datum.date.start.slice(0, -19) !=
                    modaldata.properties.Datum.date.end.slice(0, -19)
                "
              >
                <!-- Startdatum mit Zeit + Enddatum mit Zeit -->
                {{
                  modaldata.properties.Datum.date.start | moment("DD.MM.YYYY")
                }},
                {{ modaldata.properties.Datum.date.start | moment("HH:mm") }}
                bis
                {{
                  modaldata.properties.Datum.date.end | moment("DD.MM.YYYY")
                }},
                {{
                  modaldata.properties.Datum.date.end | moment("HH:mm")
                }} </span
              ><span
                class="is-size-6"
                v-if="modaldata.properties.WebsiteOrt.rich_text.length != 0"
              >
                |
                {{ modaldata.properties.WebsiteOrt.rich_text[0].plain_text }}
              </span>
            </p>

            <p
              class="is-size-6 eventtext"
              v-if="modaldata.properties.WebsiteText.rich_text.length != 0"
            >
              {{ modaldata.properties.WebsiteText.rich_text[0].plain_text }}
            </p>
            <p
              class="is-size-6"
              v-if="
                modaldata.properties.WebsiteLink.url ||
                modaldata.properties.WebsiteFile.files.length != 0 ||
                modaldata.properties.WebsiteVideoLink.url
              "
            >
              <a
                class="is-size-6 button is-medium is-primary is-outlined mr-5 mb-3"
                :href="modaldata.properties.WebsiteFile.files[0].file.url"
                target="_blank"
                v-if="modaldata.properties.WebsiteFile.files.length != 0"
                ><b-icon icon="file-download-outline" size="is-small"> </b-icon>
                &nbsp;&nbsp;&nbsp;Infos downloaden</a
              >
              <a
                class="is-size-6 button is-medium is-primary is-outlined mr-5 mb-3"
                :href="modaldata.properties.WebsiteLink.url"
                target="_self"
                v-if="modaldata.properties.WebsiteLink.url"
                ><b-icon icon="link-variant" size="is-small"> </b-icon>
                &nbsp;&nbsp;&nbsp;mehr Informationen</a
              >
              <a
                class="is-size-6 button is-medium is-primary is-outlined mr-5 v"
                :href="modaldata.properties.WebsiteVideoLink.url"
                target="_blank"
                v-if="modaldata.properties.WebsiteVideoLink.url"
                ><b-icon icon="video-outline" size="is-small"> </b-icon>
                &nbsp;&nbsp;&nbsp;online einwählen</a
              >
            </p>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script type="text/babel">
export default {
  name: "EventModal",
  components: {},
  data() {
    return {
      ismodal: true,
    };
  },
  props: {
    modaldata: [Array, Object],
  },
  methods: {
    onClose() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
.eventtext {
  white-space: break-spaces;
  width: 500px;
  max-width: 100%;
}
</style>

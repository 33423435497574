<template>
  <footer class="footer section">
    <div class="container content block">
      <hr />
      <div class="columns is-multiline">
        <News @newsmodaldata="newsmodal" />
        <Events @eventmodaldata="eventmodal" />
        <Possibilities />
        <div
          class="column is-7 is-offset-5 is-6-desktop is-offset-0-desktop is-5-widescreen"
        >
          <div class="block">
            <p class="is-size-7 supporttext">
              TechStartUp@HS-Flensburg (TeStUp) wird vom Bundesministerium für
              Bildung und Forschung über das Förderprogramm Forschung an
              Fachhochschulen mit der Maßnahme StartUpLab@FH gefördert.
            </p>
          </div>
          <Legalnav />
        </div>
      </div>
    </div>
    <div class="container is-fluid">
      <FooterLogos />
    </div>
  </footer>
</template>

<script>
import FooterLogos from "@/components/layout/FooterLogos/FooterLogos.vue";
import News from "@/components/layout/News.vue";
import Events from "@/components/layout/Events.vue";
import Possibilities from "@/components/layout/Possibilities.vue";
import Legalnav from "@/components/layout/Legalnav.vue";

export default {
  name: "Footer",
  components: {
    FooterLogos,
    News,
    Events,
    Possibilities,
    Legalnav,
  },
  data() {
    return {
      email: "",
    };
  },
  methods: {
    eventmodal: function (value) {
      this.$emit("eventmodal", value);
    },
    newsmodal: function (value) {
      this.$emit("newsmodal", value);
    },
  },
};
</script>

<style lang="scss">
.footer {
  padding: 0rem !important;
  hr {
    margin: 5rem 0rem 7rem 0rem;
    height: 1px;
  }
  .legalnav {
    margin-top: 3.25rem;
    margin-bottom: 1.25rem;
    a {
      color: $background;
      :hover {
        color: $info;
      }
    }
  }
  .newsletterheadline {
    margin-top: 1rem;
  }
  .eventheadline {
    margin-top: 0rem;
  }
  .level-left .level-item:not(:last-child) {
    margin-right: 2.75rem;
  }
  .footerblock {
    border-left: 1px solid $background;
    padding-left: 1rem;
    a {
      color: $background;
    }
  }
  .footerreadmore {
    a {
      color: $background;
    }
  }
  .fuasad {
    hr {
      margin-bottom: -4rem;
      margin-top: 9rem;
    }
    .fuaspossibility {
      .fuasicon {
        width: 4rem;
        opacity: 0.6;
      }
      color: $background;
      a {
        color: $background;
      }
      a:hover {
        color: $background;
      }
      a:hover p {
        color: $background;
      }
      a:hover img {
        opacity: 1;
      }
    }
    h3 {
      margin-bottom: 3.5rem;
      .headlineseperator {
        background-color: $primary;
        padding: 1.5rem;
      }
    }
    margin-bottom: 7.5rem;
  }
  .footerlogos {
    position: absolute;
    height: 12.5rem;
    right: 0px;
    bottom: 0px;
  }
}

//mobile, mobile image, 380px
@media screen and (max-width: 380px) {
  .footer {
    padding: 0rem 1.5rem !important;
  }
}

//mobile, mobile image, 520px
@media screen and (min-width: 381px) and (max-width: 520px) {
  .footer {
    padding: 0rem 1.5rem !important;
  }
}

//mobile, mobile image, 768px --> was min-width 521
@media screen and (min-width: 1px) and (max-width: $tablet - 1px) {
  .footer {
    padding: 0rem 1.5rem !important;
    .legalnav {
      margin-top: 3rem;
      margin-bottom: 7rem;
      flex-wrap: wrap;
      display: flex;
      justify-content: center;
      .level-item {
        margin-bottom: 0rem;
        :not(:last-child) {
          margin-right: 1.5rem;
        }
      }
    }
    .newsletterheadline {
      margin-top: 6rem;
    }
    .eventheadline {
      margin-top: 6rem;
    }
    .supporttext {
      text-align: center;
    }
    .footerlogos {
      height: 6.5rem;
      margin-right: -1.5rem;
    }
    .field.is-grouped > .control:not(:last-child) {
      margin-right: 0rem;
    }
    .field.is-grouped {
      flex-direction: column;
      p {
        margin-top: 1rem;
        button {
          width: 100%;
        }
      }
    }
    .fuasad {
      hr {
        height: 0rem;
      }
      h3 {
        margin-bottom: 3.5rem;
        .headlineseperator {
          background-color: transparent;
          padding: 0rem;
        }
      }
      .level.is-mobile {
        flex-wrap: wrap;
        .fuaspossibility {
          margin-bottom: 2rem;
        }
      }
      margin-bottom: 5rem;
    }
  }
}

//tablet-only, 1024px
@media screen and (min-width: $tablet) and (max-width: $desktop - 1px) {
  .footer {
    padding: 0rem 1.5rem !important;
    .legalnav {
      margin-top: 1rem;
      margin-bottom: 10rem;
      justify-content: flex-end !important;
    }
    .supporttext {
      text-align: right;
    }
    .footerlogos {
      height: 9rem;
      margin-right: -1.5rem;
    }
    .field.is-grouped > .control {
      flex-shrink: 1;
    }
    .fuasad {
      hr {
        height: 0rem;
      }
      h3 {
        margin-bottom: 3.5rem;
        .headlineseperator {
          background-color: transparent;
          padding: 0rem;
        }
      }
      margin-bottom: 5rem;
    }
  }
}

//desktop-only, 1216px
@media screen and (min-width: $desktop) and (max-width: $widescreen - 1px) {
  .footer {
    .legalnav {
      margin-top: 1rem;
      margin-bottom: -1rem;
    }
    .footerlogos {
      height: 8rem;
    }
  }
}

//widescreen-only, 1407px
@media screen and (min-width: $widescreen) and (max-width: $fullhd - 1px) {
  .footer {
    .legalnav {
      margin-top: 1.6rem;
      margin-bottom: 0.5rem;
    }
    .footerlogos {
      height: 10rem;
    }
  }
}
</style>

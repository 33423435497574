<template>
  <div id="app" class="Site page-wrapper">
    <EventModal
      v-if="EventmodalVisible"
      @close="closemodal"
      :modaldata="modalDataEvent"
      has-modal-card
    />
    <NewsModal
      v-if="NewsmodalVisible"
      @close="closemodal"
      :modaldata="modalDataNews"
      has-modal-card
    />
    <Navbar />
    <main class="Site-content content-wrapper">
      <router-view @eventmodal="eventmodalDataEvent" />
    </main>
    <Footer @eventmodal="eventmodalDataEvent" @newsmodal="newsmodalDataEvent" />
  </div>
</template>

<script type="text/babel">
import Navbar from "@/components/layout/Navbar.vue";
import Footer from "@/components/layout/Footer.vue";
import EventModal from "@/components/layout/EventModal.vue";
import NewsModal from "@/components/layout/NewsModal.vue";

export default {
  components: {
    Navbar,
    EventModal,
    NewsModal,
    Footer,
  },
  data() {
    return {
      EventmodalVisible: false,
      modalDataEvent: null,
      NewsmodalVisible: false,
      modalDataNews: null,
    };
  },
  methods: {
    closemodal() {
      this.EventmodalVisible = false;
      this.NewsmodalVisible = false;
    },
    eventmodalDataEvent: function (value) {
      this.modalDataEvent = value;
      this.EventmodalVisible = true;
    },
    newsmodalDataEvent: function (value) {
      this.modalDataNews = value;
      this.NewsmodalVisible = true;
    },
  },
};
</script>

<style lang="scss">
body {
  color: #fff !important;
  text-align: left;
}

.Site-content {
  padding: 0rem 1.5rem;
}

.page-wrapper {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.content-wrapper {
  flex: 1;
}

#app {
  text-align: left;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: orange;

    &.router-link-exact-active {
      color: #fff;
    }
  }
}
</style>

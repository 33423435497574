<template>
  <div class="column is-12 fuasad">
    <hr />
    <h3 class="is-size-4 has-text-centered">
      <span class="headlineseperator"
        >Möglichkeiten und Chancen an der HS Flensburg</span
      >
    </h3>
    <div class="level is-mobile">
      <div class="level-item has-text-centered fuaspossibility">
        <a href="https://hs-flensburg.de/studieninteressierte" target="_blank">
          <img class="fuasicon" :src="require(`@/assets/Icons/study.svg`)" />
          <p class="is-size-5 is-size-6-mobile mt-4">Studieren</p>
        </a>
      </div>
      <div class="level-item has-text-centered fuaspossibility">
        <a
          href="https://hs-flensburg.de/hochschule/organisation/zentrale-verwaltung/personaldienstleistungen/stellenangebote"
          target="_blank"
        >
          <img class="fuasicon" :src="require(`@/assets/Icons/work.svg`)" />
          <p class="is-size-5 is-size-6-mobile mt-4">Arbeiten</p>
        </a>
      </div>
      <div class="level-item has-text-centered fuaspossibility">
        <a href="https://hs-flensburg.de/forschung/fue" target="_blank">
          <img class="fuasicon" :src="require(`@/assets/Icons/research.svg`)" />
          <p class="is-size-5 is-size-6-mobile mt-4">Forschen</p>
        </a>
      </div>
      <div class="level-item has-text-centered fuaspossibility">
        <a
          href="https://hs-flensburg.de/forschung/entrepreneurship"
          target="_blank"
        >
          <img class="fuasicon" :src="require(`@/assets/Icons/founding.svg`)" />
          <p class="is-size-5 is-size-6-mobile mt-4">Gründen</p>
        </a>
      </div>
      <div class="level-item has-text-centered fuaspossibility">
        <a href="https://hs-flensburg.de/forschung/transfer" target="_blank">
          <img class="fuasicon" :src="require(`@/assets/Icons/transfer.svg`)" />
          <p class="is-size-5 is-size-6-mobile mt-4">Transfer</p>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Possibilities",
  components: {},
  data() {
    return {};
  },
};
</script>

<style lang="scss"></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"column is-6 is-5-desktop is-offset-1-desktop"},[_vm._m(0),_vm._l((_vm.data.results),function(event){return _c('div',{key:event.id,staticClass:"block footerblock"},[_c('a',{staticClass:"is-size-7 has-text-weight-light",on:{"click":function($event){return _vm.openModal(event)}}},[(event.properties.WebsiteHeadline.rich_text.length != 0)?_c('span',{staticClass:"is-size-6 has-text-weight-bold"},[_vm._v(" "+_vm._s(event.properties.WebsiteHeadline.rich_text[0].plain_text)+" ")]):_vm._e(),_c('br'),(
          event.properties.Datum.date.end == null &&
          event.properties.Datum.date.start.length == 10
        )?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(event.properties.Datum.date.start,"DD.MM.YYYY"))+" ")]):(
          event.properties.Datum.date.end == null &&
          event.properties.Datum.date.start.length != 10
        )?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(event.properties.Datum.date.start,"DD.MM.YYYY"))+" ab "+_vm._s(_vm._f("moment")(event.properties.Datum.date.start,"HH:mm"))+" ")]):(
          event.properties.Datum.date.end != null &&
          event.properties.Datum.date.start.length != 10 &&
          event.properties.Datum.date.start.slice(0, -19) ==
            event.properties.Datum.date.end.slice(0, -19)
        )?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(event.properties.Datum.date.start,"DD.MM.YYYY"))+" von "+_vm._s(_vm._f("moment")(event.properties.Datum.date.start,"HH:mm"))+" bis "+_vm._s(_vm._f("moment")(event.properties.Datum.date.end,"HH:mm"))+" ")]):_vm._e(),(
          event.properties.Datum.date.end != null &&
          event.properties.Datum.date.end.length == 10
        )?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(event.properties.Datum.date.start,"DD.MM.YYYY"))+" bis "+_vm._s(_vm._f("moment")(event.properties.Datum.date.end,"DD.MM.YYYY"))+" ")]):_vm._e(),(
          event.properties.Datum.date.end != null &&
          event.properties.Datum.date.end.length != 10 &&
          event.properties.Datum.date.start.slice(0, -19) !=
            event.properties.Datum.date.end.slice(0, -19)
        )?_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(event.properties.Datum.date.start,"DD.MM.YYYY"))+", "+_vm._s(_vm._f("moment")(event.properties.Datum.date.start,"HH:mm"))+" bis "+_vm._s(_vm._f("moment")(event.properties.Datum.date.end,"DD.MM.YYYY"))+", "+_vm._s(_vm._f("moment")(event.properties.Datum.date.end,"HH:mm"))+" ")]):_vm._e(),(event.properties.WebsiteOrt.rich_text.length != 0)?_c('span',[_vm._v(" | "+_vm._s(event.properties.WebsiteOrt.rich_text[0].plain_text)+" ")]):_vm._e()])])}),(_vm.isLoadingEvent)?_c('div',{staticClass:"block footerblock"},[_c('b-skeleton',{attrs:{"height":"17px","width":"50%"}}),_c('b-skeleton',{attrs:{"height":"14px","width":"35%"}})],1):_vm._e(),(_vm.isLoadingEvent)?_c('div',{staticClass:"block footerblock"},[_c('b-skeleton',{attrs:{"height":"17px","width":"40%"}}),_c('b-skeleton',{attrs:{"height":"14px","width":"45%"}})],1):_vm._e(),(_vm.isLoadingEvent)?_c('div',{staticClass:"block footerblock"},[_c('b-skeleton',{attrs:{"height":"17px","width":"60%"}}),_c('b-skeleton',{attrs:{"height":"14px","width":"25%"}})],1):_vm._e(),(_vm.isLoadingEvent)?_c('div',{staticClass:"block footerblock"},[_c('b-skeleton',{attrs:{"height":"17px","width":"55%"}}),_c('b-skeleton',{attrs:{"height":"14px","width":"50%"}})],1):_vm._e(),(_vm.isLoadingEvent)?_c('div',{staticClass:"block footerblock"},[_c('b-skeleton',{attrs:{"height":"17px","width":"30%"}}),_c('b-skeleton',{attrs:{"height":"14px","width":"25%"}})],1):_vm._e(),_c('div',{staticClass:"block footerreadmore"},[_c('a',{staticClass:"is-size-6 has-text-weight-light",attrs:{"href":"https://realisiere-deine-idee.de/uebersicht"}},[_c('b-icon',{attrs:{"icon":"arrow-right-thick","size":"is-small"}}),_vm._v(" weitere Veranstaltungen ")],1)])],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"block"},[_c('h3',{staticClass:"is-size-4 eventheadline"},[_vm._v("Kommende Veranstaltungen")])])}]

export { render, staticRenderFns }